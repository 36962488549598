.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.d-flex {
  display: flex;
}

.p-ab {
  position: absolute;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.action-button-bg {
  background: linear-gradient(180deg, #515151, #212121);
}

.padding-10 {
  padding: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.v-center {
  align-items: center;
}

.h-center {
  justify-content: center;
}

.b-radius-max {
  border-radius: 100%;
}

.z-9999 {
  z-index: 9999;
}

.drop-shadow {
  filter: drop-shadow(2px 4px 6px black);
}

.flex-column {
  flex-direction: column;
}

.neo-box {
  border-radius: 50px;
  background: #2b2b2b;
  box-shadow: inset 8px 8px 16px #1f1f1f,
              inset -8px -8px 16px #373737;
  height: 100%;
}

.neo-box-bright {
  border-radius: 53px;
  background: #2b2b2b;
  box-shadow: inset 8px 8px 16px #1e1e1e,
              inset -8px -8px 16px #2a2a2a;
  height: 100%;
}

.artistImage {
  position: absolute;
  left: 0px;
  width: 35%;
  height: 95%;
  padding: 20px 20px 10px 20px;
}

.artistInfo {
  position: absolute;
  right: 0px;
  width: 58%;
  height: 95%;
  padding: 20px 20px 10px 20px;
}

#artworkImageLower {
  position: absolute;
  height: 100%;
  transform: scale(1.5);
  z-index: 0;
  filter: blur(7px);
  max-height: 100%;
  object-fit: cover;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#rightInfoButton {
  transform: scale(0);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.action-button-link {
  position: absolute;
  text-align: center;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  margin: 20px;
  color: white;
  border-radius: 20px;
}

#leftInfoButton {
  transition: all 0.5s ease-in-out;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  position: fixed;
  text-align: center;
  color: white;
  z-index: 99999;
}

#mainContainer {
  position: absolute;
}

#title {
  position: fixed;
  font-size: 4em;
  margin-bottom: 20px;
}

#artist {
  position: absolute;
  font-size: 2em;
  color: grey;
}

.artistTextInner {
  height: 96%;
  width: 58%;
  position: absolute;
  right: 0;
  border-radius: 50px;
  background: transparent;
  box-shadow: 5px 0px 12px #2c2c2c, -6px -6px 11px #484848;
  margin: 10px 20px 10px 10px;
}

h1, h2 {
  color: white;
  transition: opacity 0.5s ease-in-out;
  font-family: 'Inter';
  font-weight: 100;
}

#loader {
  position: absolute;
  background: white;
  height: 100%;
  width: 100%;
  opacity: 1;
  z-index: 99999;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
  overflow: hidden;
}

#logo {
  position: absolute;
  z-index: 999999;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
}

#footerNav {
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 10;
  justify-content: center;
}

.loader {
  border-radius: 100%;
  animation: spin 1s infinite;
  filter: drop-shadow(0px 0px 10px grey);
  transition: all 0.5s ease-in-out;
  z-index: 999999;
}

ul {
  background: black;
  display: contents;
  list-style-type: none;
}

li {
  padding: 10px;
  margin: 10px;
  background: linear-gradient(180deg, #1b1b1b, #323232);
  border-radius: 10px;
  color: white;
}

.menu {
  border-radius: 100%;
  transition: all 0.5s ease-in-out;
  height: 70px;
  padding-top: 20px;
}

.menu:hover {
  transform: scale(1.1);
  cursor: pointer;
}

#sideDrawerOpen {
  position: absolute;
  width: 20vw;
  height: 100vh;
  background: rgb(53, 53, 53);
  z-index: 99;
  transform: translateX(-20vw);
  transition: all 0.5s ease-in-out;
}

#artInfoOpen {
  position: absolute;
  width: 100%;
  z-index: 999;
  height: 100%;
  transform: translateX(-100vw);
  background: white;
  transition: all 0.5s ease-in-out;
}

#artworkImageDiv {
  height: 100%;
  width: 100%;
  display: flex;
  transition: all 0.5s ease-in-out;
}

#artworkImage {
  width: 100%;
  object-fit: contain;
  transition: all 0.5s ease-in-out;
}

#artworkTextHeader {
  height: 38%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

#artworkTextFooter {
  height: 40%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
}

html, body {
  overscroll-behavior: none;
}

#artistInfoOpen {
  position: absolute;
  height: 100%;
  width: 100%;
  transform: translateY(-100vh);
  background: #2b2b2b;  
  transition: all 0.5s ease-in-out;
  z-index: 10;
}

#artistImageDiv {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  filter: drop-shadow(2px 4px 6px black);
}

#artistImage {
  border-radius: 100%;
}

.media-buttons { 
  width: 40px;
  padding: 20px;
  border-radius: 50px;
  background: #2b2b2b;
  box-shadow: 8px 8px 16px #1616165e, -8px -8px 16px #323232;
  transition: all 0.5s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
}

.media-buttons:hover {
  transform: scale(1.05);
}

#artistTextDiv {
  width: 100%;
  height: 50%;
  display: flex;
  color: white;
  font-family: 'inter';
  filter: drop-shadow(2px 4px 6px black);
}

#artistText { 
  margin: 5%;
  display: flex;
}

#artistTextButtons {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
  color: white;
  font-family: 'inter';
  filter: drop-shadow(2px 4px 6px black);
}

.share-buttons {
  width: 48%;
  padding: 10px;
  background: linear-gradient(180deg, #515151, #212121);
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.share-buttons:hover {
  transform: scale(1.02);
}

#artistButtonsContainer {
  width: 100%;
  margin-top: 3%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
}

.mr-1 {
  margin-right: 1%;
}

.ml-1 {
  margin-left: 1%;
}

#artworkNames {
  display: block;
  width: max-content;
  border-top: 5px solid grey;
  border-bottom: 5px solid grey;
  padding: 10px;
  color: grey;
  animation: bannermove 20s infinite;
  animation-timing-function: linear;
}

#artworkNamesReverse {
  display: block;
  width: max-content;
  border-top: 5px solid grey;
  border-bottom: 5px solid grey;
  padding: 10px;
  color: grey;
  animation: bannermove 20s infinite;
  animation-timing-function: linear;
}

#artworkNamesLower {
  display: block;
  width: max-content;
  border-top: 5px solid grey;
  border-bottom: 5px solid grey;
  padding: 10px;
  color: grey;
  animation: bannermovereverse 20s infinite;
  animation-timing-function: linear;
}

#artworkNamesReverseLower {
  display: block;
  width: max-content;
  border-top: 5px solid grey;
  border-bottom: 5px solid grey;
  padding: 10px;
  color: grey;
  animation: bannermovereverse 20s infinite;
  animation-timing-function: linear;
}

#artworkNamesContainer {
  width: max-content;
  display: flex;
  height: 50%;
  align-items: center;
  font-weight: 900;
  font-size: 1.5em;
  /* mask: linear-gradient(90deg, transparent 0%, white 2%);
  -webkit-mask: linear-gradient(90deg, transparent 0%, white 2%); */
}

#artworkNamesContainerReverse {
  width: max-content;
  display: flex;
  height: 50%;
  align-items: center;
  bottom: 0;
  font-weight: 900;
  font-size: 1.5em;
  /* mask: linear-gradient(90deg, transparent 0%, white 2%);
  -webkit-mask: linear-gradient(90deg, transparent 0%, white 2%); */
}

#closeButton {
  position: absolute;
  display: flex;
  z-index: 9999;
  padding: 20px;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transform: scale(0);
}

button {
  background: linear-gradient(90deg, #414141, white);
  color: white;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

#closeIcon {
  padding: 20px;
  border-radius: 100%;
  background: linear-gradient(180deg, #515151, #212121);
  transition: all 0.5s ease-in-out;
  width: 20px;
  height: 20px;
  filter: drop-shadow(0px 0px 10px black);
}

#zoomButton {
  position: absolute;
  display: flex;
  right: 0;
  z-index: 9999;
  padding: 20px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

#zoomIcon {
  padding: 20px;
  border-radius: 20px;  
  background: linear-gradient(180deg, #515151, #212121);
  transition: all 0.5s ease-in-out;
  width: 20px;
  height: 20px;
  filter: drop-shadow(0px 0px 10px black);
}

#viewButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: 20px;
  padding: 5px 15px 5px 5px;
  transform: scale(0);
  color: white;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 10px black);
}

#viewIcon {
  padding: 20px;
  border-radius: 20px;  
  /* background: linear-gradient(180deg, #515151, #212121); */
  transition: all 0.5s ease-in-out;
  width: 20px;
  height: 20px;
  filter: drop-shadow(0px 0px 10px black);
}

#collectionDesc {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  z-index: 999;
  top: 0%;
  transition: all 0.5s ease-in-out;
  background: white;
  box-shadow: 10px 0px 20px #484848d4;
  display: flex;
  align-items: center;
}

#artworkDesc {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  z-index: 999;
  top: 0%;
  transition: all 0.5s ease-in-out;
  background: white;
  box-shadow: 10px 0px 20px #484848d4;
  display: flex;
  align-items: center;
}

#artInfoButtons {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0%;
  transform: translateX(-100%);
}

.artistImageLogo {
  width: 90%;
  border-radius: 100%;
  height: auto;
}

@media only screen and (max-width: 1000px) {

  .artistImage {
    position: absolute;
    left: 0px;
    height: calc(40% - 30px);
    width: calc(100% - 40px);
    margin: 20px;
    padding: 0px;
  }

  .artistInfo {
    position: absolute;
    right: auto !important;
    bottom: 0;
    height: calc(60% - 30px);
    width: calc(100% - 40px);
    margin: 20px;
    padding: 0px;
  }

  .artistImageLogo {
    height: 90%;
    width: auto;
    border-radius: 100%;
  }

  #artistImageDiv {
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    filter: drop-shadow(2px 4px 6px black);
  }

  .artistTextInner {
    width: 90%;
    height: 57%;
    position: absolute;
    bottom: 0;
    right: auto !important;
    border-radius: 50px;
    background: transparent;
    box-shadow: 5px 0px 12px #2c2c2c, -6px -6px 11px #484848;
    margin: 10px 10px 15px 15px;
  }

  #artistImage {
    width: auto;
    height: 90%;
  }

  #artistTextDiv {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: baseline;
    bottom: 0;
    color: white;
    font-family: 'inter';
    filter: drop-shadow(2px 4px 6px black);
  }

  #artistTextButtons {
    width: 100%;
    height: 50%;
    display: flex;
    position: absolute;
    align-items: end;
    justify-content: center;
    bottom: 0;
    right: 0;
    color: white;
    font-family: 'inter';
    filter: drop-shadow(2px 4px 6px black);
  }

  #artistButtonsContainer {
    width: 100%;
    padding: 0% 4% 10% 4%;    
    display: flex;
    text-align: center;
    cursor: pointer;
  }

}

@media only screen and (max-width: 700px) {
  #closeIcon {
    padding: 20px;
    border-radius: 100%;
    background: linear-gradient(180deg, #515151, #212121);
    transition: all 0.5s ease-in-out;
    width: 10px;
    height: 10px;
    filter: drop-shadow(0px 0px 10px black);
  }

  #viewButton {
    position: absolute;
    display: flex;
    width: calc(100% - 60px);
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin: 20px;
    padding: 5px 15px 5px 5px;
    transform: scale(0);
    color: white;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 10px black);
  }
  
  #zoomIcon {
    padding: 20px;
    border-radius: 20px;    
    background: linear-gradient(180deg, #515151, #212121);
    transition: all 0.5s ease-in-out;
    width: 10px;
    height: 10px;
    filter: drop-shadow(0px 0px 10px black);
  }

  #title {
    position: fixed;
    font-size: 1.4em;
    margin-bottom: 20px;
  }
  
  #artist {
    position: absolute;
    font-size: 1.3em;
    color: grey;
  }

  #artistText {
    font-size: 0.9em;
    display: flex;
  }

  #artworkNamesContainer {
    font-size: 0.8em;
  }

  #artworkNamesContainerReverse {
    font-size: 0.8em;
  }

  .menu {
    border-radius: 100%;
    transition: all 0.5s ease-in-out;
    height: 50px;
    padding-top: 20px;
  }


}

#footerDisclaimer {
  height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: end;
    color: grey;
    font-family: 'Inter';
    font-size: 0.5em;
}

.padding-10 {
  padding: 10px;
}

@keyframes spin {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(-1);  }
  100% {
    transform: scaleX(1);  }
}

@keyframes bannermove {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@keyframes bannermovereverse {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
}